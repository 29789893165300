import axios from "axios"



export const create = (item, token) => {
  return axios.post(process.env.VUE_APP_API_URL + `cms/`, {
    ...item
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (id, item, token) => {
  return axios.put(process.env.VUE_APP_API_URL + `cms/` + id, {
    ...item
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `cms/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getCategories = (token) => {
  return axios.get(process.env.VUE_APP_API_URL + `cms/distinct/categories`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getAll = (search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `cms/all?search=${search}&count=${count}&page=${page}&withCategory=true`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const deleteR = (id, token) => {
  return axios.delete(process.env.VUE_APP_API_URL + `cms/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}