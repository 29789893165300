<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-overlay :show="isBusy">
        <b-card>
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form class="needs-validation" @submit.prevent="handleSubmit(itemFormSubmit)" novalidate>
              <h6 class="heading-small text-muted mb-4">Content</h6>

              <div class="">
                <b-row>

                  <b-col lg="6">
                    <base-input type="text" :name="'Description - English'" label="Description - English" :required="true"
                      placeholder="Description - English" v-model="itemForm.published_value.en">
                    </base-input>
                  </b-col>
                  <b-col lg="6">
                    <base-input type="text" :name="'Description - Arabic'" label="Description - Arabic" :required="true"
                      placeholder="Description - Arabic" v-model="itemForm.published_value.ar">
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <base-input type="text" :name="'Key'" label="Key" :required="true" placeholder="Key"
                      v-model="itemForm.key">
                    </base-input>
                  </b-col>

                  <b-col lg="6">
                    <base-input type="select" :name="'Category'" label="Category" :required="true" placeholder="Category"
                      @input="updateInput($event)" :options="categories" :key-field="'value'" :isAddNewAllowed="true" :value-field="'value'"
                      :text-field="'name'" v-model="itemForm.category">
                    </base-input>
                  </b-col>


                </b-row>
              </div>

              <base-button type="primary" native-type="submit" class="my-4">
                {{ isUpdate() ? "Update Information" : "Create" }}
              </base-button>
            </b-form>
          </validation-observer>



        </b-card>
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import moment from "moment";
import MediaInput from "@/components/Inputs/MediaInput";
import * as categoriesRepo from "@/api-repo/categories";
import * as cmsRepo from "@/api-repo/cms";

import { handleError, successMessage } from "@/util/error-handler";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    MediaInput,
    HtmlEditor
  },
  data() {
    return {
      isBusy: false,
      categories: [],
      itemForm: {
        key: null,
        category:null,
        published_value: { en: null, ar: null },
      },
    };
  },
  created() {
    if (this.isUpdate()) {
      this.getData();
    }
  },

  methods: {
    updateInput(value) {
      let rawCats = this.categories.length > 0 ? this.categories.map(e => e.value): [];
      if(!rawCats.includes(value)){
        this.itemForm.category = this.categories[this.categories.length - 1].value;
      }
    },


    generateBreadCrumb() {
      return [{ label: "KeyValues", route: "CMS" }, { label: this.isUpdate() ? this.itemForm.published_value.en : "Add New KeyValue" }];
    },

    getData: async function () {
      try {

        this.isBusy = true;
        let catResp = await cmsRepo.getCategories(this.userStore.state.token);
        this.categories = catResp.data.data.length > 0 ? catResp.data.data.map(e => { return { value: e, name: e } }) : [];
        let result = await cmsRepo.getById(
          this.$route.params.id,
          this.userStore.state.token
        );
        this.itemForm.key = result.data.data.key;
        this.itemForm.category = result.data.data.category;
        this.itemForm.published_value = result.data.data.published_value;
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },
    isUpdate: function () {
      return this.$route.params.id != null;
    },
    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        this.itemForm.sort_order = this.itemForm.sort_order ? parseInt(this.itemForm.sort_order) : null;
        //this.itemForm.visible = this.itemForm.visible;
        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            let result = await cmsRepo.create(
              this.itemForm,
              this.userStore.state.token
            );
            successMessage("Item created successfully");
            this.$router.replace({ path: "/content/cms" });
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
            console.log(  this.itemForm)
            let result = await cmsRepo.update(
              this.$route.params.id,
              this.itemForm,
              this.userStore.state.token
            );
            successMessage("Item updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },

  },
};
</script>
